<template>
    <v-dialog :overlay-opacity="globalDialogAction === 'logout' ? 1 : 0.5" persistent v-model="dialog" max-width="500">
        <v-card>
            <v-card-title>
                <div class="d-flex align-center primary--text">
                    <v-icon class="mr-1">error</v-icon>
                    {{ globalDialogTitle }}
                </div></v-card-title
            >
            <v-spacer></v-spacer>
            <v-divider></v-divider>

            <v-card-text class="mt-1">
                {{ globalDialogText }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    @click="goToWelcomePage"
                    v-if="['returnToWelcomePage', 'refreshInstanceSnapshots'].includes(globalDialogAction)"
                    color="secondary"
                    :disabled="fetchingSnapshots"
                    text
                    class="caption font-weight-bold"
                    >go to dashboard</v-btn
                >
                <v-btn v-if="globalDialogAction === 'logout'" @click="logout" color="secondary" text class="caption font-weight-bold">logout</v-btn>
                <v-btn
                    @click="$store.dispatch('hideGlobalDialog')"
                    v-if="globalDialogAction === 'dismiss'"
                    color="secondary"
                    text
                    class="caption font-weight-bold"
                    >Dismiss</v-btn
                >
                <v-btn v-if="globalDialogAction === 'reload'" color="secondary" text class="caption font-weight-bold" @click="reloadPage">Reload</v-btn>
                <v-btn
                    v-if="globalDialogAction === 'refreshInstanceSnapshots'"
                    @click="refreshInstanceSnapshots"
                    :loading="fetchingSnapshots"
                    color="secondary"
                    text
                    class="caption font-weight-bold"
                    >refresh</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import store from 'store'

export default {
    name: 'GlobalDialog',
    data() {
        return {
            dialog: false
        }
    },
    computed: {
        ...mapState(['globalDialog', 'globalDialogTitle', 'globalDialogText', 'globalDialogAction', 'userOrgs']),
        ...mapGetters('snapshotStore', ['currentSnapshotLockMode']),
        ...mapState('instanceStore', ['fetchingSnapshots'])
    },
    methods: {
        goToWelcomePage: function() {
            this.$router.push({ name: 'home-welcome' })
            this.$store.dispatch('hideGlobalDialog')
        },
        reloadPage: function() {
            location.reload()
        },
        logout() {
            store.remove('nvTarget')
            this.$auth.logOut()
        },
        refreshInstanceSnapshots: function() {
            this.$store.dispatch('instanceStore/fetchInstanceSnapshots', this.$route.params.iid)
        }
    },
    watch: {
        globalDialog: function(nextVal, preVal) {
            this.$data.dialog = nextVal
        }
    },
    beforeDestroy() {
        this.$store.dispatch('hideGlobalDialog')
    }
}
</script>
