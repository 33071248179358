var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "overlay-opacity": _vm.globalDialogAction === "logout" ? 1 : 0.5,
        persistent: "",
        "max-width": "500"
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "d-flex align-center primary--text" },
              [
                _c("v-icon", { staticClass: "mr-1" }, [_vm._v("error")]),
                _vm._v(" " + _vm._s(_vm.globalDialogTitle) + " ")
              ],
              1
            )
          ]),
          _c("v-spacer"),
          _c("v-divider"),
          _c("v-card-text", { staticClass: "mt-1" }, [
            _vm._v(" " + _vm._s(_vm.globalDialogText) + " ")
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              ["returnToWelcomePage", "refreshInstanceSnapshots"].includes(
                _vm.globalDialogAction
              )
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: {
                        color: "secondary",
                        disabled: _vm.fetchingSnapshots,
                        text: ""
                      },
                      on: { click: _vm.goToWelcomePage }
                    },
                    [_vm._v("go to dashboard")]
                  )
                : _vm._e(),
              _vm.globalDialogAction === "logout"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: { color: "secondary", text: "" },
                      on: { click: _vm.logout }
                    },
                    [_vm._v("logout")]
                  )
                : _vm._e(),
              _vm.globalDialogAction === "dismiss"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: { color: "secondary", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.$store.dispatch("hideGlobalDialog")
                        }
                      }
                    },
                    [_vm._v("Dismiss")]
                  )
                : _vm._e(),
              _vm.globalDialogAction === "reload"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: { color: "secondary", text: "" },
                      on: { click: _vm.reloadPage }
                    },
                    [_vm._v("Reload")]
                  )
                : _vm._e(),
              _vm.globalDialogAction === "refreshInstanceSnapshots"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "caption font-weight-bold",
                      attrs: {
                        loading: _vm.fetchingSnapshots,
                        color: "secondary",
                        text: ""
                      },
                      on: { click: _vm.refreshInstanceSnapshots }
                    },
                    [_vm._v("refresh")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }